<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Comtech Interfaces</h3>
		</div>

		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<div v-if="error" class="pt-5">
					<b-alert show variant="warning" class="m-0">
						<span class="svg-icon-danger">
							<inline-svg src="/media/svg/icons/Code/Warning-2.svg"></inline-svg>
						</span>
						{{ errorMsg }}
					</b-alert>
				</div>
				<dygraphs v-else-if="dyData.length > 0" :data="dyData" :options="dyOpts" />
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import { KMG } from '@/helpers';

const date = new Date();
const offset = date.getTimezoneOffset() * 60000;
export default {
	props: ['name', 'prom'],
	name: 'SolarwindsComtechTemp',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		dygraphs: () => import('@/view/content/lib/dygraphs.vue'),
	},
	data() {
		return {
			loaded: false,
			dyData: [],
			dyOpts: {},
			data: {},
			error: false,
			errorMsg: 'There was an error retrieving data for this device',
		};
	},
	methods: {
		processData() {
			if (!this.data.data?.data?.data || this.data.data.data.data.length == 0) {
				throw { data: data, e: 'no data' };
			}
			var labels = ['Timestamp'];
			var graphdata = [];
			var dddd = this.data.data.data.data;
			var first = 1;

			Object.keys(dddd).forEach(ts => {
				if (dddd[ts]['int']) {
					var row = [new Date(ts * 1000 + offset)];
					Object.keys(dddd[ts]['int']).forEach(n => {
						var k = dddd[ts]['int'][n];
						if (first == 1) {
							labels.push('Int ' + n + ' In');
							labels.push('Int ' + n + ' Out');
						}
						// IN
						if (typeof k[0] !== 'undefined') {
							row.push(k[0]);
						} else {
							row.push(null);
						}
						// OUT
						if (typeof k[1] !== 'undefined') {
							row.push(k[1]);
						} else {
							row.push(null);
						}
					});
					graphdata.push(row);
					first = 0;
				}
			});

			this.dyOpts = {
				labels: labels,
				includeZero: true,
				labelsKMG2: false,
				axes: {
					y: {
						valueFormatter: function(y) {
							return KMG(y) + 'bps';
						},
						axisLabelFormatter: function(y) {
							return KMG(y);
						},
					},
				},
				connectSeparatedPoints: true,
				labelsSeparateLines: false,
				fillGraph: true,
				strokeWidth: 1.5,
				fillAlpha: 0.06,
			};

			this.dyData = graphdata;
		},
		load() {
			this.prom
				.then(d => {
					this.data = d;
					this.processData();
					this.loaded = true;
				})
				.catch(e => {
					if (e.data && e.data == 'No data for selected range') {
						this.error = true;
						this.errorMsg = e.data;
					} else {
						this.errorMsg = 'There was an error retrieving data for this device.  ' + e;
						this.error = true;
					}
					this.loaded = true;
				});
		},
	},
	watch: {
		prom() {
			this.load();
		},
	},
	created() {
		this.load();
	},
};
</script>

<style></style>
